/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ref } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import axios, { AxiosResponse } from 'axios';
import { ContactForm } from '~/types/forms.types';

const useBrevoForm = () => {
  const response: Ref<AxiosResponse> = ref(null);
  const success: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const error: Ref<any> = ref(null);

  const sendFormWithBrevo = async (data: ContactForm, type: string) => {
    success.value = false;
    error.value = null;
    loading.value = true;

    try {
      const formData = {
        ...data,
        type,
      };
      const sendFormResponse = await axios({
        method: 'get',
        url: '/api/brevo/sendForm',
        headers: {
          Accept: 'text/plain, json/application',
        },
        params: { formData },
      });
      response.value = sendFormResponse.data.data;
      error.value = sendFormResponse.data.error;
      if (sendFormResponse?.data?.error) {
        success.value = false;
        return;
      }
      success.value = true;
    } catch (e) {
      error.value = e;
      success.value = false;
    }
    loading.value = false;
  };

  return {
    sendFormWithBrevo,
    response,
    success,
    loading,
    error,
  };
};

export default useBrevoForm;
